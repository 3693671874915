<template>
    <div class="pdf-demo" id="demo" ref="demo">
    </div>
</template>

<script>
    import Pdfh5 from "pdfh5";
    import "pdfh5/css/pdfh5.css";
    
    var tid, tsid, timer;
    import { getCoursewareDetail, finishViewDocProcess} from '@/api/task.js';
    import { toast, checkLogin} from '@/utils/common.js';
    // import { getUser } from '../api/common.js';
    // import { setWaterMark, removeWatermark } from "../utils/watermark";
    export default {
        data() {
            return{
                pdfh5: null,
                info: null,
                tsid: '',
                tid: '',
                cur: '',
                list: [],
                coursewareUser: null,
            }
        },
        activated() {
            tid = this.tid = this.$route.query.id; // 任务id2
            tsid = this.tsid = this.$route.query.tsid;// 任务结果id
            this.cur = +this.$route.query.cur;// 当前课程索引
            checkLogin().then(()=> {
                this.getDetail(tid, tsid);
            })
            
            this.hideTab();
        },
        deactivated(){
            clearTimeout(timer);
            // removeWatermark();
        },
        methods:{
            initPdf(pdfurl){
                if(this.pdfh5){
                    this.pdfh5.destroy();
                    this.pdfh5 = null;
                }
                this.pdfh5 = new Pdfh5(this.$refs.demo, {
                   pdfurl: pdfurl,
                }).on('complete', (status, msg)=>{
                    if(status == 'error'){
                        return toast(msg);
                    }
                    if(this.info.clu){
                        return;
                    }
                    if(timer){
                        clearTimeout(timer);
                    }
                    timer = setTimeout(()=>{
                        // finishViewDocProcess
                        var data = {
                            lesson: this.info.name,
                        };
                        var clid = this.info['id'];
                        finishViewDocProcess(tid, clid, data).then((ret)=>{
                            // 并不需要标记
                            console.log('已完成该课程', ret);
                        }).catch((err)=>{
                            toast(err.msg|| '更新总进度失败！', 'error')
                        })
                    },1000*60*1)
                })
            },
            getDetail(tid, tsid){
                getCoursewareDetail(tid, tsid).then((ret)=>{
                    var data = ret.data;
                    this.list = data.lessons;
                    this.info = data.lessons[this.cur];
                    this.coursewareUser = data.coursewareUser;
                    this.initPdf(this.info['file_url']);
                    // this.getUser();
                })
            },
            hideTab(){
                //console.log(document.querySelector('#vantab'));
                document.querySelector('#vantab').classList.add('tab-hide');
            },
        }
    }
</script>

<style scoped>
    .pdf-demo .viewerContainer{
        height: 100vh;
    }
    /* @import "pdfh5/css/pdfh5.css"; */
</style>